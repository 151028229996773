<template>
  <div style="width:100%;height:300px;overflow-x: auto;">
      <v-chart :options="polar"/>
  </div>

</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/polar'
export default {
  components: {
    'v-chart': ECharts
  },
  props:{
    xdata:{
      type:Array,
      default:[]
    },
    ydata:{
      type:Array,
      default:[]
    },
    title:{
      type:String,
      default:''
    }
  },
  mounted() {
  },
  watch: {},
  created() {
    this.loadData();
  },
  data () {
    return {
      polar: Object
    }
  },
  methods:{
    loadData(){
        this.polar={
          tooltip:
          {
            trigger: 'axis'
          }, 
          grid: {
						left: '25',
						right: '0',
						bottom: '0',
						containLabel: true
					},                  
          xAxis: {                
            type: 'category',
            data: this.xdata,//xdata
            axisLabel:{
              interval: 0,
              rotate:80,
            }
          },
          dataZoom: [
            {
                xAxisIndex: 5,
                show: true,
                type: 'slider', 
                startValue: 0, 
                endValue: 6 
            }
          ],
          yAxis: {
            name: this.title,
            type: 'value'
          },
          series: [
            {
              data:  this.ydata,//ydata
              type: 'line',
              smooth: true,
            }
          ],
          animationDuration: 2000
        }
        this.polar.autoresize=true;
    }
  }
}
</script>
<style scoped>
  .echarts {
    width: 100%;
    height: 100%;       
  }
</style>
